<template>
  <div class="">
    <Parent />
    <Table
      class="mt-3"
      :loading="loading"
      :headers="headers"
      :items="getSupplierData"
      :total-records="totalRecords"
      :number-of-pages="totalPages"
      @update:options="updateTable"
      @row-clicked="editSupplier"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between">
          <div class="ml-4 my-7">
            <v-card
              color="#525252"
              flat
              height="29px"
              width="130px"
              class="ml-4"
            >
              <div class="d-flex justify-center align-center">
                <v-icon class="">$salesPerson</v-icon>
                <div class="font-18px white--text ml-2">
                  取引先一覧
                </div>
              </div>
            </v-card>
          </div>
          <div class="d-flex mt-6 mr-5">
            <div class="search-field">
              <TableSearch
                :search.sync="searchTable"
                @update:search="updateTable"
              ></TableSearch>
            </div>
            <v-btn
              @click="
                $router.push({
                  name: 'SupplierCreate',
                  params: { shop_id: $route.params.shop_id }
                })
              "
              text
              color="primary"
              class="ml-6"
              ><v-icon left>$plus</v-icon>新規登録</v-btn
            >
          </div>
        </div>
      </template>
      <template v-slot:item.business_partner_name="{ item }">
        <div class="d-flex justify-start">{{ item.business_partner_name }}</div>
      </template>
      <template v-slot:item.category="{ item }">
        <span class="mr-2" v-for="(cat, index) in item.category" :key="index">
          <template v-if="index < item.category.length - 1">
            {{ cat }},
          </template>
          <template v-else>
            {{ cat }}
          </template>
        </span>
      </template>
      <template v-slot:item.transaction_amount="{ item }">
        {{ item.transaction_amount | toCurrency }}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon @click="editSupplier(item)">$edit</v-icon>
      </template>
      <template v-slot:item.created_at="{ item }">
        <div class="">
          {{ item.created_at | formatDate("YYYY-MM-DD") }}
        </div>
      </template>
    </Table>
  </div>
</template>
<script>
import Table from "@/components/admin/partials/Table/Table";
import Parent from "@/views/admin/Store/Index";
import TableSearch from "@/components/admin/partials/Search/TableSearch";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  components: {
    Table,
    Parent,
    TableSearch
  },
  data() {
    return {
      searchTable: "",
      loading: true,
      pagination: null,
      suppliers: [],
      headers: [
        {
          text: "No.",
          value: "id",
          align: "center",
          sortable: false,
          width: 100
        },
        {
          text: "取引先名",
          value: "business_partner_name",
          sortable: false,
          width: 200
        },
        {
          text: "カテゴリー",
          value: "category",
          sortable: false,
          width: 330
        },
        {
          text: "総取引額",
          value: "transaction_amount",
          align: "center",
          sortable: true,
          width: 130
        },
        {
          text: "登録日",
          value: "created_at",
          align: "center",
          sortable: true,
          width: 130
        },
        {
          text: "",
          value: "edit",
          align: "center",
          sortable: false,
          width: 80
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getSuppliers", "getSupplierPagination", "getMasterData"]),
    totalRecords() {
      if (this.getSupplierPagination !== undefined)
        return this.getSupplierPagination.records_total;
      else return 0;
    },
    totalPages() {
      if (this.getSupplierPagination !== undefined)
        return this.getSupplierPagination.total_pages;
      else return 0;
    },
    getSupplierData() {
      return this.getSuppliers.map(supplier => {
        let categories = [];
        for (let i = 0; i < supplier.category.length; i++) {
          let category = this.getMasterData.partners_category.find(
            data => data.id === supplier.category[i]
          );
          categories.push(category.value);
        }
        return {
          id: supplier.id,
          business_partner_name: supplier.business_partner_name,
          transaction_amount: supplier.transaction_amount,
          category: categories,
          created_at: dayjs(supplier.created_at).format("YYYY-MM-DD")
        };
      });
    }
  },
  methods: {
    updateTable(e) {
      this.fetchData(e);
    },

    async fetchData(e = undefined) {
      this.loading = true;
      let params = {};
      params.shop_id = this.$route.params.shop_id;
      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        params.order = e.sortDesc[0] ? "desc" : "asc";
        params.sort = e.sortBy[0];
      } else {
        params.sort = "created_at";
        params.order = "desc";
      }

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        params.page = e.page;
      }
      params.paginate = e.itemsPerPage ? e.itemsPerPage : 50;

      if (this.searchTable) {
        params.search = this.searchTable;
      }

      await this.$store.dispatch("SUPPLIER_GET_ALL", params).then(() => {
        this.loading = false;
      });
    },
    editSupplier({ id }) {
      this.$router.push({
        name: "SupplierEdit",
        params: {
          shop_id: this.$route.params.shop_id,
          id: id
        }
      });
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
